<template>
  <div class="">
    <div class="mt-2 mb-4 content-filtros">
      <div class="filtros d-flex ml-5">
        <div class="filtros-graficos">
          Visualizado por:
          <input v-model="dateFilter" type="radio" id="diario" name="filtro" value="day">
          <label for="diario"> diário </label>
        </div>
        <div class="filtros-graficos">
          <input v-model="dateFilter" type="radio" id="semanal" name="filtro" value="week">
          <label for="semanal"> semanal </label>
        </div>
        <div  class="filtros-graficos">
          <input v-model="dateFilter" type="radio" id="mensal" name="filtro" value="month">
          <label for="mensal"> mensal </label>
        </div>
      </div>
    </div>
    <v-progress-circular  v-if="loader" class="loader" indeterminate color="primary" />
    <hr>  
    <!-- GRAFICO -->
    <div class="">
        <grafico :formatLabel="dateFilter" />
    </div>

    <!-- <div class="dr-title text-left ml-4 mt-4 mb-2">
      Detalhamento
    </div> -->

    <!-- <div class="table">
      <v-data-table
        :headers="headers"
        :items="result.data || []"
        hide-default-footer
        disable-pagination
        @click:row="visibleLancamento"
        class="table-row"
        >
        <template v-slot:item.Records_groupByMonth="{ item }">
            {{ formatType(item) }}
        </template>
      </v-data-table>
    </div> -->

  </div>
</template>

<script>
import Events from '@/core/service/events'
import moment from 'moment'
moment.locale('pt-br')
import { map } from 'lodash'
// eslint-disable-next-line
const namespaceStore = 'organizzeRelatoriosVendasProdutos'
export default {
  name: 'vendasProdutosFiltro',
  components: {
    grafico: () => import('./grafico.vue')
  },
  mounted () {
    const vm = this
    this.dateFilter = 'day'
    this.filtroCampo = this.tiposSelect[1]
    Events.$on('loader:component', (v) => {
      vm.loader = v
    })
  },
  data () {
    return {
      pendentes: true,
      dateFilter: 'day',
      loader: false,
      filtroProdutos: [],
      filtroCampo: {},
      tiposSelect: [
        {text: 'Pagamento', id: 'dt_paid' },
        {text: 'Emissão', id: 'dt_emission' },
        {text: 'Vencimento', id: 'dt_due' },
      ],
      headers: [{ text: 'período(pgamento vencimento)', value: 'Records_groupByMonth' },
      { text: 'Receitas', value: 'Records_outcome'},
      { text: 'Custeio', value: 'costing.Records_income' },
      { text: 'Despesas', value: 'Records_income' },
      { text: 'Resultado', value: 'Records_result' },
      { text: 'Saldo', value: 'Records_balance' }]
    }
  },
  watch: {
    'dateFilter' () {
      this.selectCampoTable()
    },
    'pendentes' () {
      this.selectCampoTable()
    },
    'filtroProdutos' (v) {
      this.selectCampoTable(v)
    }
  },
  computed: {
    formatResult () {
      const vm = this
      const res = this.listProdutos
      const dados = map(res, v => {
        v.text = vm.formatType(v)
        return v
      })
      return dados
    },
    filtroSelectCampo () {
      let atual = this.filtroCampo.id
      const dados = this.tiposSelect
      return dados.filter(e => e.id !== atual)
    }
  },
  methods: {
    formatType(v) {
      let type = this.dateFilter
      if (type ==='week') {
        let end = moment().weeks(v.Records_Week + 1).year(v.Records_Year).startOf('weeks').format('DD MMM')
        return moment().weeks(v.Records_Week + 1).year(v.Records_Year).endOf('weeks').format(`[${end} à] DD MMM`)
      }
      if (type ==='day') {
        return moment(v.Records_Date).format('DD/MM/YYYY')
      }
      return `${v.Records_Month}/${v.Records_Year}`
    },
    selectCampoTable (produtos) {
      Events.$emit('loader:component', true)
      // campos para filtros
      this.$emit('input', {
        table: this.filtroCampo.id,
        data: this.dateFilter,
        produtos
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input {
  margin-top: 0;
}
.dr {
  &-title {
    color: #4a5275;
    font-size: 19px;
  }
}
.content-filtros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.filtros{
  &-graficos {
    input {
      display: none;
    }
    label {
      cursor: pointer;
      padding: 0 15px;
      color: #757575;
      font-size:16px
    }
    input:checked + label {
      color: #1cb4ff;
      font-weight: bold;
      font-size:16px
    }
  }
}
.loader{
  position: absolute;
  top: 200px;
  z-index: 99999;
}
.table::v-deep .v-data-table .v-data-table__wrapper table tbody tr{
  cursor: pointer;
}

</style>